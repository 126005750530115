import React from "react"
import { FlexCenter, LinearTitle } from "../styles/elements.js"
import styled from "styled-components"
import "normalize.css" // Note this

import Seo from "../components/Seo"
import Layout from "../components/layout.js"

import NetlifyForm from "../components/NetlifyForm.js"

import { Paragraph } from "../styles/elements.js"

const Contact = props => {
  return (
    <>
      <Layout>
        <Seo title="About CompoundYourself" />
        <FlexCenter>
          <LinearTitle>Contact</LinearTitle>
        </FlexCenter>
        <Paragraph>
          Have a story to tell? A request to make? An idea for me to write
          about? Let me know through the form below.
        </Paragraph>
        <NetlifyForm />
        <Margin />
      </Layout>
    </>
  )
}

const Margin = styled.div`
  margin: 6rem 0rem;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`

export default Contact
